import { NgIf, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { fadeInOutTop } from '@shared/animations/fade-in-out-top';
import { Toast } from '@shared/models/toast.model';
import { Observable } from 'rxjs';
import { ToastService } from './toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [fadeInOutTop],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, AsyncPipe],
})
export class ToastComponent {
  public showToast$: Observable<Toast | undefined>;

  constructor(private toastService: ToastService) {
    this.showToast$ = this.toastService.showToast$;
  }

  public close(): void {
    this.toastService.closeToast();
  }
}

@NgModule({
  imports: [ToastComponent],
  exports: [ToastComponent],
})
export class ToastModule {}
